// 1. React and Hooks
import React, { useState } from 'react';

// 2. Third-Party Libraries
import { Link } from 'react-router-dom';
import { IoMdSunny, IoMdMoon } from "react-icons/io";
import { MdLanguage } from "react-icons/md";
import { useTranslation } from 'react-i18next';

// 3. Local Components
import Logo from '../Logo/Logo';

// 4. Assets (Images, etc.)

// 5. Styles
import './Header.css';

const Header = ({ theme, toggleTheme, pickLanguage }) => {
  const { t, i18n } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <header className="header" role="banner" aria-label={t("header-aria-label")}>
        <div className="header-left">
          <Link to="/" aria-label={t("header-home-link")}>
            <Logo theme={theme} type={'inline'} />            
          </Link>
        </div>
        <nav className="nav" role="navigation" aria-label={t("header-navigation-aria")}>
          <ul className="nav-list">
            <li className="nav-item">
              <Link to="/ponuda">
                <button className="dropbtn" aria-haspopup="true" aria-expanded="false">
                  {t("header-offer")}
                </button>
              </Link>
              <div className='underline-container'>
                <span className='underline'></span>
              </div>
              <div className="dropdown-content" role="menu" aria-label={t("header-dropdown-offer")}>
                <Link to="/ponuda?model=PRODAJA">{t("header-selling")}</Link>
                <Link to="/ponuda?model=NOVOGRADNJA">{t("header-new-development")}</Link>
                <Link to="/ponuda?model=IZDAVANJE">{t("header-renting")}</Link>
              </div>
            </li>
            <li className="nav-item">
              <button className="dropbtn" aria-haspopup="true" aria-expanded="false">
                {t("header-information")}
              </button>
              <div className='underline-container'>
                <span className='underline'></span>
              </div>
              <div className="dropdown-content" role="menu" aria-label={t("header-dropdown-information")}>
                <Link to="/o-nama">{t("header-about-us")}</Link>
                <Link to="/saradnja">{t("header-cooperation")}</Link>
              </div>
            </li>
            <li className="nav-item">
              <Link to="/kontakt">{t("header-contact")}</Link>
              <div className='underline-container'>
                <span className='underline'></span>
              </div>
            </li>
          </ul>
          <a href="https://apartmani-junona.rs" target="_blank" rel="noopener noreferrer" aria-label={t("header-apartments-junona-link")}>
            <Logo theme={theme} type={'apartments'} altText={t("header-junona-apartments-alt")}/>   
          </a>
        </nav>
        <div className="header-right">
          <div className="language-dropdown">
            <ul className="nav-list">
              <li className="nav-item">
                <button className="dropbtn" aria-haspopup="true" aria-expanded="false">
                  <MdLanguage /> {t("header-language")}
                </button>
                <div className='underline-container'>
                  <span className='underline'></span>
                </div>
                <div className="dropdown-content" role="menu" aria-label={t("header-dropdown-language")}>
                  <button onClick={() => pickLanguage('sr')}>Srpski</button>
                </div>
              </li>
            </ul>
          </div>
          <button onClick={toggleTheme} className="theme-toggle" aria-label={theme === 'light' ? t("header-switch-to-dark") : t("header-switch-to-light")}>
            {theme === 'light' ? <IoMdMoon /> : <IoMdSunny />}
          </button>
        </div>
        <button className="hamburger-menu" onClick={toggleSidebar} aria-label={t("header-open-menu")}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </header>

      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`} role="complementary" aria-label={t("header-sidebar")}>
        <ul>
          <li className="nav-item">
            <Link to="/ponuda" onClick={toggleSidebar}>
              <button className="dropbtn">
                {t("header-offer")}
              </button>
            </Link>
            <div className="dropdown-content" role="menu">
              <Link to="/ponuda?model=PRODAJA" onClick={toggleSidebar}>{t("header-selling")}</Link>
              <Link to="/ponuda?model=NOVOGRADNJA" onClick={toggleSidebar}>{t("header-new-development")}</Link>
              <Link to="/ponuda?model=IZDAVANJE" onClick={toggleSidebar}>{t("header-renting")}</Link>
            </div>
          </li>
          <li className="nav-item">
            <button className="dropbtn" onClick={toggleSidebar}>{t("header-information")}</button>
            <div className="dropdown-content" role="menu">
              <Link to="/o-nama" onClick={toggleSidebar}>{t("header-about-us")}</Link>
              <Link to="/saradnja" onClick={toggleSidebar}>{t("header-cooperation")}</Link>
            </div>
          </li>
          <li className="nav-item">
            <Link to="/kontakt" onClick={toggleSidebar}>{t("header-contact")}</Link>
          </li>
          <li className="nav-item">
            <a href="https://apartmani-junona.rs" target="_blank" rel="noopener noreferrer" aria-label={t("header-apartments-junona-link")}>
              <Logo theme={theme} type={'apartments'} altText={t("header-junona-apartments-alt")}/>   
            </a>
          </li>
          <li className="nav-item">
            <button className="dropbtn"><MdLanguage /> {t("header-language")}</button>
            <div className="dropdown-content" role="menu">
              <button onClick={() => { pickLanguage('sr'); toggleSidebar(); }}>Srpski</button>
            </div>
          </li>
          <li className="nav-item">
            <button onClick={() => { toggleTheme(); toggleSidebar(); }} className="theme-toggle" aria-label={theme === 'light' ? t("header-switch-to-dark") : t("header-switch-to-light")}>
              {theme === 'light' ? <IoMdMoon /> : <IoMdSunny />}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
