import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
    }
  },
  sr: {
    translation: {
        // HEADER
        "header-aria-label": "Glavna navigacija",
        "header-home-link": "Vrati se na početnu stranicu",
        "header-navigation-aria": "Glavni navigacioni meni",
        
        "header-offer": "PONUDA",
        "header-selling": "Prodaja",
        "header-renting": "Izdavanje",
        "header-new-development": "Novogradnja",
        "header-dropdown-offer": "Padajući meni ponude nekretnina",
        
        "header-information": "INFORMACIJE",
        "header-blog": "Blog",
        "header-about-us": "O nama",
        "header-cooperation": "Saradnja",
        "header-dropdown-information": "Padajući meni informacija",
        
        "header-contact": "KONTAKT",
        
        "header-apartments-junona-link": "Link do Apartmani Junona platforme",
        "header-junona-apartments-alt": "Apartmani Junona logo",
        
        "header-language": "Srpski",
        "header-dropdown-language": "Padajući meni za izbor jezika",

        "header-switch-to-dark": "Prebaci se u tamni režim",
        "header-switch-to-light": "Prebaci se u svetli režim",
        
        "header-open-menu": "Otvori bočni meni",
        "header-sidebar": "Bočni navigacioni meni",

      
        // FOOTER
        "footer-having-questions": "Da li imate pitanja za nas?",
        "footer-contact-us": "Kontaktirajte nas",
        "footer-useful-links": "Korisni linkovi",

        "footer-useful-link-kopaonik-new": "Novogradnja apartmani Kopaonik",
        "footer-useful-link-kopaonik-sale": "Prodaja starogradnje Kopaonik",
        "footer-useful-link-kraljevo-houses": "Prodaja kuća Kraljevo",
        "footer-useful-link-kraljevo-apartments": "Prodaja stanova Kraljevo",
        "footer-useful-link-belgrade-apartments": "Prodaja stanova Beograd",

        "footer-kopaonik": "Planirate odmor na Kopaoniku?",
        "footer-checkout-apartments": "Posetite našu platformu za izdavanje apartmana",
        "footer-apartments-junona": "APARTMANI JUNONA",
        "footer-all-rights-reserved": "Sva prava zadržana",


        // HERO
        "hero-background-alt": "Ilustracija grada u Nekretnine Junona svetu",
        "hero-background-portrait-alt": "Ilustracija grada u Nekretnine Junona svetu",
        "hero-billboard-alt": "Bilbord Nekretnine Junona",
        "hero-billboard-portrait-alt": "Bilbord Nekretnine Junona",
        "hero-lights-alt": "Svetla na bilbordu Nekretnine Junona",
        "hero-lights-portrait-alt": "Svetla na bilbordu Nekretnine Junona",
        "hero-title-line-1": "Ne čekaj da kupiš nekretninu.",
        "hero-title-line-2": "Kupi nekretninu i čekaj.",
        "hero-button": "POGLEDAJTE PONUDU",
        "hero-subtext": "(kupite nekretninu bez provizije)",


        // HOMEPAGE
        "homepage-document-title": "Početna | Nekretnine Junona",
        "homepage-meet-us-title": "Agencija koja donosi promene u svet nekretnina",
        "homepage-meet-us-subtitle": "Naša agencija uvek stavlja interes klijenata na prvo mesto",
        "homepage-meet-us-description": "Mi uvek težimo da pružimo najbolje usluge klijentima, sa fokusom na transparentnost i poverenje. Naša agencija je tu da vas vodi kroz svaki korak, bilo da kupujete ili prodajete nekretninu.",
        "homepage-about-us-button": "Upoznajte nas",

        "homepage-property-image-1-alt": "Kuća u Kraljevu",
        "homepage-property-image-2-alt": "Moderan apartman na Kopaoniku u objektu Goma u Vikend naselju",
        "homepage-property-image-3-alt": "Moderan apartman u Konacima na Kopaoniku",
        "homepage-property-image-4-alt": "Kafe bar na Kopaoniku",
        "homepage-property-image-5-alt": "Apartman u objektu Gencian Resort na Kopaoniku u naselju Treska",

        "homepage-sell-property-title": "Prodajte svoju nekretninu lako",
        "homepage-sell-property-subtitle": "Dozvolite nam da vam olakšamo prodaju nekretnine po najboljoj ceni",
        "homepage-sell-property-description": "Bilo da prodajete stan, kuću ili zemljište, bićemo tu da olakšamo proces kako bismo postigli najbolju cenu na tržištu za vašu nekretninu. Pružamo kompletne usluge, od procene vrednosti do marketinga i vođenja pregovora.",
        "homepage-cooperation-button": "Saznajte više",

        "homepage-sell-property-benefit-1": "✓ Oglašavanje nekretnine",
        "homepage-sell-property-benefit-2": "✓ Procena nekretnine i savetovanje",
        "homepage-sell-property-benefit-3": "✓ Pregovaranje sa kupcima",
        "homepage-sell-property-benefit-4": "✓ Velika vidljivost na internetu",
        "homepage-sell-property-benefit-5": "✓ Izrada ugovora za vas",

        "homepage-new-developments-title": "Najveća ponuda apartmana na Kopaoniku",
        "homepage-new-developments-subtitle": "Mi jako volimo Kopaonik, upoznajte sa nama sve čari ove planine",
        "homepage-new-developments-description": "Naša ekskluzivna ponuda luksuznih apartmana na Kopaoniku savršena je za ljubitelje prirode i skijanja. Pružamo vrhunske lokacije i sadržaje, sa mogućnostima ulaganja u nekretnine visoke vrednosti.",
        "homepage-look-at-offer-button": "Pogledajte ponudu",

        "homepage-kopaonik-image-1-alt": "Luksuzni apartman na Kopaoniku noću",
        "homepage-kopaonik-image-2-alt": "Planinska vila u Kopaoniku okružena snegom",
        "homepage-kopaonik-image-3-alt": "Apartman u prirodnom okruženju na Kopaoniku",


        // CONTACT
        "contact-document-title": "Kontakt | Nekretnine Junona",
        "contact-page-title": "Hajde da pričamo!",
        "contact-page-subtitle": "Kontaktirajte nas, želimo da vas upoznamo!",
        "contact-call-us": "Pozovite nas",
        "contact-email-us": "Pošaljite nam email",
        "contact-visit-us": "Posetite nas",
        "contact-find-us": "Pronadjite nas na mapi",
        "contact-location": "Lokacija kancelarije agencije za nekretnine Junona",
        "contact-follow-us": "Zapratite nas",
        "contact-follow-us-facebook": "Zapratite nas na Fejsbuku",
        "contact-follow-us-instagram": "Zapratite nas na Instagramu",

        "contact-logo-alt": "Nekretnine Junona logo",
        "contact-contact-us": "Javite nam se",
        "contact-contact-us-aria": "Forma za slanje upita",
        "contact-your-name": "Vaše ime",
        "contact-phone-number": "Broj telefona",
        "contact-choose-options": "Izaberite opciju",
        "contact-option-sale-interest": "Zanima me prodaja nekretnine",
        "contact-option-investment-company": "Predstavljam investitorsku kompaniju",
        "contact-option-purchase-interest": "Zanima me kupovina nekretnine",
        "contact-option-rental-interest": "Zanima me izdavanje nekretnine",
        "contact-option-leasing-interest": "Zanima me iznajmljivanje nekretnine",
        "contact-option-information-only": "Samo želim da se informišem",
        "contact-option-other": "Ostalo",
        "contact-input-message": "Unesite poruku",
        "contact-submit-button": "POŠALJI",
        "contact-submit-success": "Uspešno ste poslali upit!",
        "contact-submit-failure": "Došlo je do greške prilikom slanja upita!",


        // COLLABORATION
        "collaboration-document-title": "Saradnja | Nekretnine Junona",
        "collaboration-page-title": "Hajde da saradjujemo!",
        "collaboration-page-subtitle": "Izaberite opciju koja vas najbolje opisuje i saznajte kako bi naša saradnja izgledala!",
        "collaboration-choose-option": "Izaberite opciju koja vas najbolje opisuje",
        "collaboration-seller-button": "Želim da <strong>prodam</strong> nekretninu koju posedujem",
        "collaboration-company-button": "Predstavljam <strong>investitorsku firmu</strong>",
        "collaboration-issuer-button": "Želim da <strong>izdam</strong> svoju nekretninu na duži vremeski period",
        "collaboration-buyer-button": "Želim da <strong>kupim</strong> nekretninu",
        "collaboration-renter-button": "Želim da <strong>iznajmim</strong> nekretninu",

        "collaboration-seller-title": "Želite da prodate vašu nekretninu?",
        "collaboration-seller-description": "Došli ste na pravo mesto. Za samo <strong>2%</strong> kupoprodajne cene nekretnine prilikom prometa dobijate potpunu podršku na svakom koraku.",
        "collaboration-seller-benefits": [
          "Fotografisanje nekretnina",
          "Izrada reklamnog materijala",
          "Oglašavanje nekretnine",
          "Velika vidljivost na internetu",
          "Procena nekretnine i savetovanje",
          "Pregovaranje sa kupcima",
          "Prezentacija nekretnine kupcima",
          "Izrada predugovora ili ugovora",
          "Priprema i pomoć prilikom overe"
        ],
        "collaboration-company-title": "Predstavljate investitorsku firmu?",
        "collaboration-company-description": "Gradite i razmišljate o podršci agencije u prodaji. Naša provizija je <strong>2%</strong> i za vas smo pripremili dva modela saradnje.",
        "collaboration-company-model1-title": "Model 1: Podrška u prodaji",
        "collaboration-company-model1-description": "Podelićete vašu ponudu sa nama, vaša ponuda stanova će postati deo naše ponude i biti dostupna našim klijentima. Dobijate kompletnu podršku u prodaji vaših stambenih jedinica našim klijentima.",
        "collaboration-company-model1-benefits": [
          "Pregovaranje sa kupcima",
          "Prezentacija ponude",
          "Marketing i oglašavanje"
        ],
        "collaboration-company-model2-title": "Model 2: Prodajni agent",
        "collaboration-company-model2-description": "Želite da se bavite gradnjom, ali ne i prodajom? Prepustite prodaju nama. Ukoliko ste spremni da nam ustupite mesto ekskluzivne (jedine) agencije koja će vas zastupati, za isti iznos provizije, dobijate potpuno gratis sledeće benefite:",
        "collaboration-company-model2-benefits": [
          "Izrada website-a",
          "Marketing i oglašavanje",
          "Vodjenje društvenih mreža",
          "Snimanje reklamnog materijala",
          "Velika vidljivost na internetu",
          "Pregovaranje sa kupcima",
          "Prezentacija ponude"
        ],
        "collaboration-issuer-title": "Želite da izdate vašu nekretninu na duži period?",
        "collaboration-issuer-description": "Došli ste na pravo mesto. Za samo <strong>50%</strong> iznosa prve rente dobijate potpunu podršku na svakom koraku.",
        "collaboration-issuer-benefits": [
          "Fotografisanje nekretnina",
          "Izrada reklamnog materijala",
          "Oglašavanje nekretnine",
          "Velika vidljivost na internetu",
          "Procena nekretnine i savetovanje",
          "Pregovaranje sa kupcima",
          "Prezentacija nekretnine kupcima",
          "Izrada ugovora"
        ],
        "collaboration-buyer-title": "Želite da kupite nekretninu?",
        "collaboration-buyer-description": "Za vas smo pripremili dva modela saradnje.",
        "collaboration-buyer-model1-title": "Model 1: Regularna ponuda",
        "collaboration-buyer-model1-description": "Pogledajte našu ponudu i kontaktirajte nas. Naša agencija ne naplaćuje proviziju kupcima nekretnina iz naše ponude.",
        "collaboration-buyer-model2-title": "Model 2: Premium kupac",
        "collaboration-buyer-model2-description": "Želite da kupite nekretninu što pre? Želite prioritetni pristup ponudi? Za samo <strong>2%</strong> provizije prilikom prometa nekretnine naš agent će postati vaš lični agent i pružiće vam kompletnu podršku prilikom kupovine. U okviru ovog modela dobijate:",
        "collaboration-buyer-model2-benefits": [
          "Pristup široj ponudi",
          "Prioritetni pristup ponudi",
          "Aktivno traženje nekretnine",
          "Pregovaranje sa prodavcem",
          "Potpuna podrška i savetovanje"
        ],
        "collaboration-renter-title": "Želite da iznajmite nekretninu?",
        "collaboration-renter-description": "Pogledajte ponudu stanova za izdavanje na našem sajtu. Naša agencija ne naplaćuje proviziju iznajmljivaču prilikom iznajmljivanja nekretnine.",

        "collaboration-action-button-contact": "Kontaktirajte nas!",
        "collaboration-action-button-offer": "Pogledajte ponudu",
        

        // ABOUT US
        "about-us-document-title": "O nama | Nekretnine Junona",
        "about-us-page-title": "Ko smo mi?",
        "about-us-page-subtitle": "Želimo da vam ispričamo priču o nama",
        "about-us-section-aria": "Sekcija o nama",
        "about-us-our-story-alt": "Osnivači agencije za nekretnine, majka i sin, gledaju u gradjevinski projekat",
        "about-us-our-story-title": "Naša priča",
        "about-us-our-story-text-1": "Sve je počelo 2022. godine na Kopaoniku. Tada je, na predlog jedne od gradjevinskih kompanija sa kojom smo saradjivali, prvi put nastala ideja o našem ulasku u svet nekretnina. Krenuli smo duboko uvereni da tržištu nedostaje pouzdan partner, neko ko neće staviti profit iznad potreba klijenata, već će se posvetiti izgradnji dugoročnih odnosa zasnovanih na poverenju.",
        "about-us-our-story-text-2": "Naš ulazak u nekretnine nije bio slučajan. Iza nas je više od deset godina iskustva u knjigovodstvu, gde smo stekli poverenje brojnih klijenata, uključujući i investitorske kompanije sa Kopaonika. Kroz to iskustvo, prirodno je proizašla ideja da proširimo naše usluge i na tržište nekretnina, vodjeni istim principima odgovornosti, posvećenosti i iskrenosti.",
        "about-us-our-story-text-3": "Na čelu naše firme je Daliborka Tonić, osnivač i direktor, po profesiji ekonomista. Njena najveća snaga leži u iskrenom, otvorenom pristupu i dubokom razumevanju potreba klijenata, što je rezultat višegodišnjeg rada u finansijama. Daliborka vodi firmu u saradnji sa sinom Danilom, koji je podržava i brine o marketingu i istraživanju tržišta, i zajedno grade porodični biznis sa jasnom vizijom – pružiti klijentima sigurnost da su njihove investicije u dobrim rukama.",
        
        "about-us-our-vision-title": "Naša vizija",
        "about-us-our-vision-text": "Vrlo prosto, želimo da nas klijenti prepoznaju kao partnere na koje uvek mogu da se oslone. Verujemo da uspeh ne zavisi samo od brojeva, već od odnosa koje gradimo sa ljudima. Želimo da svaki klijent oseća sigurnost i poverenje u svakom koraku, bilo da kupuje ili investira.",
        "about-us-empathy-title": "EMPATIJA.",
        "about-us-empathy-text": "Svaki naš klijent ima svoju jedinstvenu priču i potrebe, i mi to duboko poštujemo. Naš cilj je da budemo više od običnog posrednika – da budemo neko ko razume ljude i stvara im siguran prostor za najvažnije životne odluke.",
        "about-us-professionalism-title": "PROFESIONALIZAM.",
        "about-us-professionalism-text": "Naš tim se vodi principima odgovornosti i profesionalizma u svakom aspektu našeg rada. Posvećeni smo tome da svakom klijentu pružimo najbolju moguću uslugu, uz maksimalnu posvećenost detaljima.",
        "about-us-trust-title": "POVERENJE.",
        "about-us-trust-text": "Poverenje je osnova svake saradnje, i mi to ne uzimamo olako. Iskrenost i transparentnost su ključne vrednosti na kojima gradimo našu reputaciju, i trudimo se da uvek budemo tu za naše klijente – u svakom trenutku.",
        
        "about-us-our-services-title": "Naše usluge",
        "about-us-service-1-title": "Pristup proverenoj ponudi",
        "about-us-service-1-text": "Kao posrednici u prometu nekretnina, pružamo vam pristup pažljivo odabranim nekretninama koje su prošle kroz našu detaljnu proveru. Naš cilj je da klijentima omogućimo sigurne investicije i pouzdane izbore, bilo da tražite stan za život ili za investiciju.",
        "about-us-service-2-title": "Personalizovana podrška",
        "about-us-service-2-text": "Verujemo da je svaki klijent jedinstven, i zato prilagođavamo naš pristup svakom pojedincu. Bilo da prvi put kupujete nekretninu ili ste iskusan investitor, pružamo vam stručne savete i podršku kroz svaki korak procesa, od prvog pregleda do zaključenja kupoprodaje.",
        "about-us-service-3-title": "Transparentnost i poverenje",
        "about-us-service-3-text": "Naša kompanija stavlja transparentnost i poverenje na prvo mesto. Svaka nekretnina u našoj ponudi je temeljno proverena, a sve informacije koje delimo sa klijentima su tačne i precizne. Sa nama znate da ste u sigurnim rukama, bez skrivenih troškova ili neprijatnih iznenadjenja.",
        
        "about-us-why-us-title": "Zašto baš mi?",
        "about-us-why-us-main-reason": "Zato što želite pouzdanog i iskrenog saradnika koji može da završi posao.",
        "about-us-why-us-sub-reason": "Zato ne gubite vreme",
        "about-us-contact-us": "Kontaktirajte nas",


        // BLOG
        "blog-home-page-title": "Blog",
        "blog-home-page-subtitle": "Pročitajte više o raznim temama i uplovite sa nama u svet nekretnina!",


        // OFFER PAGE
        "offer-page-document-title": "Ponuda | Nekretnine Junona",
        "offer-page-show-filters": "Prikaži filtere",
        "offer-page-filter-remove": "Poništi",

        // ITEM PAGE
        "item-page-failed-to-fetch": "Došlo je do greške, tražena nekretnina verovatno ne postoji u bazi!",
        "item-page-loading": "Učitavanje",
        "item-page-success": "Uspešno ste poslali upit!",
        "item-page-failure": "Došlo je do greške",
        "item-page-about-estate": "O nekretnini",
        "item-page-key-features": "Ključne karakteristike",
        "item-page-features": "Osobine",
        "item-page-location": "Lokacija",
        "item-page-conclusion": "Zaključak",
        "item-page-is-this-for-you": "Da li je ova nekretnina za vas?",
        "item-page-provision": "provizije",
        "item-page-contact-us": "Kontaktirajte nas",
        "item-page-or": "ili",
        "item-page-report-interest": "Prijavite interesovanje",
        "item-page-name": "Ime",
        "item-page-phone": "Broj telefona",
      }
  },
  ru: {
    translation: {
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'sr', 
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
