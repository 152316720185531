// 1. React and Hooks
import React, { useEffect, useState } from 'react';

// 2. Third-Party Libraries
import { useTranslation } from 'react-i18next';
import { useSearchParams, Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';

// 3. Local Components
import OfferCard from '../../components/OfferCard/OfferCard';

// 5. Styles
import './OfferPage.css';

const OfferPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  // State for the offers and filters
  const [offers, setOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [error, setError] = useState(null);
  const [models, setModels] = useState([]);
  const [cities, setCities] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);

  // Filter states
  const [model, setModel] = useState(searchParams.get('model') || '');
  const [type, setType] = useState(searchParams.get('type') || '');
  const [city, setCity] = useState(searchParams.get('city') || '');
  const [minArea, setMinArea] = useState(parseFloat(searchParams.get('minArea')) || '');
  const [maxArea, setMaxArea] = useState(parseFloat(searchParams.get('maxArea')) || '');
  const [minPrice, setMinPrice] = useState(parseFloat(searchParams.get('minPrice')) || '');
  const [maxPrice, setMaxPrice] = useState(parseFloat(searchParams.get('maxPrice')) || '');
  
  // Dropdown visibility states
  const [areaDropdownVisible, setAreaDropdownVisible] = useState(false);
  const [priceDropdownVisible, setPriceDropdownVisible] = useState(false);
  const [modelDropdownVisible, setModelDropdownVisible] = useState(false);
  const [cityDropdownVisible, setCityDropdownVisible] = useState(false);
  const [typeDropdownVisible, setTypeDropdownVisible] = useState(false);

  // State to control the filters bar visibility
  const [filtersVisible, setFiltersVisible] = useState(false);

  // Fetch offers from the API
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = t('offer-page-document-title');

    const fetchOffers = async () => {
      try {
        const response = await fetch(`${API_URL}/get_offers`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': API_KEY
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch offers');
        }

        const data = await response.json();
        setOffers(data);

        // Extract unique models, cities, and property types
        setModels([...new Set(data.map(offer => offer.model))]);
        setCities([...new Set(data.map(offer => offer.address.city))]);
        setPropertyTypes([...new Set(data.map(offer => offer.selected_type).filter(Boolean))]);

        // Apply the filters automatically
        applyFilters(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchOffers();
  }, [t, API_URL, API_KEY]);

  // Apply filters automatically on page load
  const applyFilters = (offersData) => {
    const filtered = offersData.filter(offer => {
      const matchesModel = model ? offer.model === model : true;
      const matchesType = offer.selected_type ? (type ? offer.selected_type === type : true) : true;
      const matchesCity = city ? offer.address.city === city : true;
  
      const actualArea = offer.special_features.area || (offer.special_features.land_area * 100);
      const matchesArea = (!minArea || actualArea >= minArea) && (!maxArea || actualArea <= maxArea);
      
      // Only apply price filter if the offer has a price (i.e., exclude projects)
      const matchesPrice = offer.price ? 
        (!minPrice || offer.price >= minPrice) && (!maxPrice || offer.price <= maxPrice) : true;
  
      return matchesModel && matchesType && matchesCity && matchesArea && matchesPrice;
    });
  
    setFilteredOffers(filtered);
  };

  const applyFiltersOnClick = () => {
    applyFilters(offers);

    setAreaDropdownVisible(false);
    setPriceDropdownVisible(false);
    setModelDropdownVisible(false);
    setCityDropdownVisible(false);
    setTypeDropdownVisible(false);

    setFiltersVisible(false);
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  return (
    <>
      <div className="container darker-background">
        <div className={"filters-bar-container"}>
          {filtersVisible ? '' : 
            <button className="filter-toggle-button" onClick={toggleFilters}>
              {t('offer-page-show-filters')}
            </button>
          }
          <div className={`filters-bar ${filtersVisible ? '' : 'filters-bar-invisible'}`}>
            {/* Model filter with radio buttons */}
            <div className="filter-dropdown">
              <button className={`filter-item ${model ? 'filter-item-active' : ''}`} onClick={() => setModelDropdownVisible(!modelDropdownVisible)}>
                {model || 'Model'}
              </button>
              {modelDropdownVisible && (
                <div className="dropdown-content-filter">
                  {models.map((modelOption, index) => (
                    <label key={index}>
                      <input
                        type="radio"
                        value={modelOption}
                        checked={model === modelOption}
                        onChange={() => setModel(modelOption)}
                      />
                      {modelOption}
                    </label>
                  ))}
                  <button className="inverse-colors" onClick={() => setModel('')}>{t("offer-page-filter-remove")}</button>
                </div>
              )}
            </div>

            {/* City filter with radio buttons */}
            <div className="filter-dropdown">
              <button className={`filter-item ${city ? 'filter-item-active' : ''}`} onClick={() => setCityDropdownVisible(!cityDropdownVisible)}>
                {city || 'Grad'}
              </button>
              {cityDropdownVisible && (
                <div className="dropdown-content-filter">
                  {cities.map((cityOption, index) => (
                    <label key={index}>
                      <input
                        type="radio"
                        value={cityOption}
                        checked={city === cityOption}
                        onChange={() => setCity(cityOption)}
                      />
                      {cityOption}
                    </label>
                  ))}
                  <button className="inverse-colors" onClick={() => setCity('')}>{t("offer-page-filter-remove")}</button>
                </div>
              )}
            </div>

            {/* Property type filter with radio buttons */}
            <div className="filter-dropdown">
              <button className={`filter-item ${type ? 'filter-item-active' : ''}`} onClick={() => setTypeDropdownVisible(!typeDropdownVisible)}>
                {type || 'Tip'}
              </button>
              {typeDropdownVisible && (
                <div className="dropdown-content-filter">
                  {propertyTypes.map((typeOption, index) => (
                    <label key={index}>
                      <input
                        type="radio"
                        value={typeOption}
                        checked={type === typeOption}
                        onChange={() => setType(typeOption)}
                      />
                      {typeOption}
                    </label>
                  ))}
                  <button className="inverse-colors" onClick={() => setType('')}>{t("offer-page-filter-remove")}</button>
                </div>
              )}
            </div>

            {/* Area filter with min and max input */}
            <div className="filter-dropdown">
              <button className={`filter-item ${minArea || maxArea ? 'filter-item-active' : ''}`} onClick={() => setAreaDropdownVisible(!areaDropdownVisible)}>
                {t('Površina (m²)')}
              </button>
              {areaDropdownVisible && (
                <div className="dropdown-content-filter">
                  <div className="min-max-inputs">
                    <div className="min-max-input">
                      <input
                        type="number"
                        value={minArea}
                        onChange={e => setMinArea(e.target.value)}
                        placeholder={t('min')}
                        className="filter-input"
                      />
                      <div className="unit">m²</div>
                    </div>
                    <div className="min-max-input">
                      <input
                        type="number"
                        value={maxArea}
                        onChange={e => setMaxArea(e.target.value)}
                        placeholder={t('max')}
                        className="filter-input"
                      />
                      <div className="unit">m²</div>
                    </div>
                  </div>
                  <button className="inverse-colors" onClick={() => { setMinArea(''); setMaxArea(''); }}>{t("offer-page-filter-remove")}</button>
                </div>
              )}
            </div>

            {/* Price filter with min and max input */}
            <div className="filter-dropdown">
              <button className={`filter-item ${minPrice || maxPrice ? 'filter-item-active' : ''}`} onClick={() => setPriceDropdownVisible(!priceDropdownVisible)}>
                {t('Cena (€)')}
              </button>
              {priceDropdownVisible && (
                <div className="dropdown-content-filter">
                  <div className="min-max-inputs">
                    <div className="min-max-input">
                      <input
                        type="number"
                        value={minPrice}
                        onChange={e => setMinPrice(e.target.value)}
                        placeholder={t('min')}
                        className="filter-input"
                      />
                      <div className="unit">€</div>
                    </div>
                    <div className="min-max-input">
                      <input
                        type="number"
                        value={maxPrice}
                        onChange={e => setMaxPrice(e.target.value)}
                        placeholder={t('max')}
                        className="filter-input"
                      />
                      <div className="unit">€</div>
                    </div>
                  </div>
                  <button className="inverse-colors" onClick={() => { setMinPrice(''); setMaxPrice(''); }}>{t("offer-page-filter-remove")}</button>
                </div>
              )}
            </div>

            {/* Search button */}
            <button className="search-button" onClick={applyFiltersOnClick}>
              <FaSearch />
            </button>
          </div>
        </div>
      </div>

      {/* Display filtered offers */}
      <div className="container">
        <div className="offers">
          {error ? (
            <div className="error">{error}</div>
          ) : (
            filteredOffers.map((offer, index) => (
              <Link
                key={index}
                to={`/ponuda/item?selected_type=${offer.selected_type || "NOVOGRADNJA"}&id=${offer.id}`}
                className="offer-link"
              >
                <OfferCard
                  model={offer.model}
                  imageUrl={API_URL + offer.image_url}
                  type={offer.selected_type || 'NOVOGRADNJA'}
                  price={typeof offer.price === 'number' 
                    ? `${Intl.NumberFormat('de-DE').format(offer.price)} €` 
                    : offer.price === null ? 'Cena na upit' : 'N/A'}
                  settlement={offer.address.settlement}
                  city={offer.address.city}
                  special_features={offer.special_features}
                />
              </Link>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default OfferPage;
